import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';

class Footer extends Component {
  render(){
    return(
      <footer>
      	<div className="footer-top">
      		<div className="footer-text">StreamsGate - Streams links for all matches. Watch the latest games for free! StreamsGate.com offers the latest soccer streams links,NBA streams links,NFL streams links, NHL  streams links, MLB, MMA, Boxing, F1 and motogp  streams links. Visit StreamsGate.com sport pages to get live stream links for your favorite team like Real Madrid, FC Barcelona, Arsenal, Chelsea, Bayern, Juventus, Liverpool,PSG...
      	</div>
      	</div>
      		<div className="footer-bottom center-text">
      		<div className="footer-bottom-left center-text">
      			<img src="/logosg2.png" className="footer-main-logo" alt="StreamsGate logo"/>
      			<div className="copyright-text">© 2020 StreamsGate.tv</div>
      			<div className="copyright-link-container">
      				<NavLink className="copyright-link-item" to="/terms-and-conditions">Terms of Use </NavLink>
      				<NavLink className="copyright-link-item" to="/privacy-policy">Privacy policy </NavLink>
      				<NavLink className="copyright-link-item" to="/contact-us">Contact</NavLink>
      			</div>
      		</div>
      		<div className="footer-bottom-right center-text">
      			<div className="links-social copyright-link-container">
              		<p>Created By <a className="copyright-link-item" href="https://redly.dev">redly.dev</a></p>
      			</div>
      		</div>
      	</div>
      </footer>
    );
  }
}
export default Footer;
