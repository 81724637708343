import React, { Component } from 'react';

const { DateTime } = require("luxon");

class GameItem extends Component {
  state = {
    game:this.props.game,
    sport:this.props.sport
  }
  time(x){
    return typeof x !== 'number' ?  DateTime.fromISO(x).toLocaleString({
      weekday: 'long', month: 'long', day: 'numeric',
      hour: "2-digit",
      minute: "2-digit",
      hour12: false
    }) :  DateTime.fromMillis(x*1000).toLocaleString({
      weekday: 'long', month: 'long', day: 'numeric',
      hour: "2-digit",
      minute: "2-digit",
      hour12: false
    })
  }
  linker(game){
    switch(this.props.sport){
      case 'football': return `https://new.soccerstreams-100.tv/event/${this.state.game.l.replaceAll('.','-')}/${this.state.game.n.replaceAll(' ','-')}-live-soccer-streams/${this.state.game.i}`
      case 'nba' :return `https://live1.nbastreams100.com/game/${this.state.game.n.replaceAll(' ','-')}-live-stream/`
      case 'mma' :return `https://live1.mmastreams100.com/${this.state.game.n.replaceAll(' ','-')}-live-stream`
      case 'boxing' :return `https://live1.boxingstreams100.com/fight/${this.state.game.i}/${this.state.game.n.replaceAll(' ','-')}`
      case 'f1':return `https://main.f1streams100.com/race/${this.state.game.i}/${this.state.game.gPrx.replaceAll(' ','-')}`
      case 'nfl':return `https://nflstreams-100.tv/match/${this.state.game.n.replaceAll(' ','-')}/${this.state.game.i}`
      case 'motogp': return `https://main.motogpstreams100.com/race/${this.state.game.i}/${this.state.game.n.replaceAll(' ','-')}`
      default : return '/'
    }
  }
  render(){
    return(
      <div className="matches-item">
      { !['f1','motogp','mma','boxing'].includes(this.state.sport)?
          (<a href={this.linker(this.state.game).toLowerCase()}>
          <div className="matches-info">
            <div className="home-team">
              <span className="home-team-logo-link center-text">
                <img src={(this.props.sport!=='football'?
                  'https://a3.espncdn.com/i/teamlogos/'+ (this.props.sport!=='football'?this.props.sport:'soccer')+ '/500/'
                        : "https://new.soccerstreams-100.tv/api/img/soccer/")+
                    (this.props.sport!=='football'?this.state.game.c[0].t.ab.toLowerCase():this.state.game.c[0].t.i)+'.png?w=50&h=50'}
                     className="home-team-logo" alt={this.state.game.c[0].t.sd}/>
              </span>
              <span className="home-team-name center-text">
                <p>{this.state.game.c[0].t.sd}</p>
              </span>
            </div>
            <div className="result-container">
              <div className="result">
                <span className="result-dash">vs</span>
              </div>
              <div className="status-container responsive-text">
                <span className="start-time">{ this.time(this.state.game.d) }</span>
              </div>
            </div>
            <div className="away-team">
              <span className="away-team-logo-link center-text">
                <img src={'https://a3.espncdn.com/i/teamlogos/'+(this.props.sport!=='football'?this.props.sport:'soccer')+'/500/'+(this.props.sport!=='football'?this.state.game.c[1].t.ab.toLowerCase():this.state.game.c[1].t.i)+'.png?w=50&h=50'} className="away-team-logo" alt={this.state.game.c[1].t.sd}/>
              </span>
              <span className="away-team-name center-text">
                <p>{this.state.game.c[1].t.sd}</p>
              </span>
            </div>
            <div className="info-container">
              <div className="info-content responsive-text">
                <span className="league-name" data-lid="9901">
                  {this.state.sport === 'football' ? this.state.league : this.state.sport.toUpperCase()}
                </span>
              </div>
            </div>
          </div>
        </a>) : (
          <a href={this.linker(this.state.game).toLowerCase()}>
            <div className="matches-info">
              <span className="home-team-name center-text">
                <p>{this.state.sport==='f1'?this.state.game.gPrx:this.state.game.n}</p>
              </span>
              <div className="info-container">
                <div className="info-content responsive-text">
                  <span className="start-time">{['f1','boxing'].includes(this.state.sport)?this.state.game.d : this.time(this.state.game.d) }</span>
                </div>
              </div>
            </div>
          </a>
        )}
      </div>
    );
  }
}
export default GameItem;
