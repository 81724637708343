import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';

class Header extends Component {
  render(){
    return(
      <header>
        <div className="header-blue-container">
          <div className="header-top">
            <div className="container-brand">
              <img className="brand-logo" src="/sglogo2.png" alt="StreamsGate logo"/>
            </div>
          </div>
          <div className="header-bottom center-text header-filter stick-header">
            <div className="main-menu">
              <NavLink to="/" className="menu-item"  activeClassName="active">
                <span className="icon-Soccer"><i className="far fa-futbol"/></span>
                <span className="menu-label">Soccer</span>
              </NavLink>
              <NavLink to="/nba" className="menu-item"  activeClassName="active">
                <span className="icon-NBA"><i className="fa fa-basketball-ball"/></span>
                <span className="menu-label">NBA</span>
              </NavLink>
              <NavLink to="/nfl" className="menu-item"  activeClassName="active">
                <span className="icon-NFL"><i className="fa fa-football-ball"/></span>
                <span className="menu-label">NFL</span>
              </NavLink>
              <NavLink to="/mlb" className="menu-item"  activeClassName="active">
                <span className="icon-MLB"><i className="fa fa-baseball-ball"/></span>
                <span className="menu-label">MLB</span>
              </NavLink>
              <NavLink to="/nhl" className="menu-item"  activeClassName="active">
                <span className="icon-NHL"><i className="fa fa-hockey-puck"/></span>
                <span className="menu-label">NHL</span>
              </NavLink>
              <NavLink to="/mma" className="menu-item"  activeClassName="active">
                <span  className="icon-MMA"><i className="fa fa-allergies"/></span>
                <span className="menu-label">MMA</span>
              </NavLink>
              <NavLink to="/boxing" className="menu-item"  activeClassName="active">
                <span className="icon-Boxing"><i className="fa fa-sign-language"/></span>
                <span className="menu-label">Boxing</span>
              </NavLink>
              <NavLink to="/formula-one" className="menu-item"  activeClassName="active">
                <span className="icon-F1"><i className="fa fa-car"/></span>
                <span className="menu-label">F1</span>
              </NavLink>
              <NavLink to="/moto-gp" className="menu-item"  activeClassName="active">
                <span className="icon-MotoGP"><i className="fa fa-motorcycle"/></span>
                <span className="menu-label">MotoGP</span>
              </NavLink>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
