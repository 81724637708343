import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import GameItem from './GameItem';
import WebService from "../WebService";

class Sport extends Component {
  state = {
    data: [],
    seoData:[],
    loaded: false,
    error: '',
    leagues:[
      {name:'soccer',url:'https://new.soccerstreams-100.tv/',uid:107036},
      {name:'nba',url:'https://www.nbastreams100.com/',uid:107038},
      {name:'nfl',url:'https://www.nflstreams100.com/',uid:107039},
      {name:'mlb',url:'https://www.mlbstreams100.com/',uid:107043},
      {name:'nhl',url:'https://www.nhlstreams100.com',uid:107041},
      {name:'mma',url:'https://www.mmastreams100.com/',uid:107046},
      {name:'boxing',url:'https://www.boxingstreams100.com/',uid:107047},
      {name:'f1',url:'https://home.f1streams100.com/',uid:107051},
      {name:'motogp',url:'https://www.motogpstreams100.com/',uid:107049},
      {name:'terms-and-conditions',uid:107059},
      {name:'privacy-policy',uid:107061},
      {name:'contact-us',uid:107063}
    ]
  }
  async componentDidMount() {
    try {
      this.setState({
        data: await WebService.getFixture(this.props.sport ? this.props.sport : 'football'),
        loaded: true
        //seoData: await WebService.getSeo(this.props.sport ? this.props.sport : 'soccer')
      });
    } catch (err) {
      this.setState({error:err.message,loaded: true});
    }
	}
  render(){
    return(
        <>
          <div className="application">
            <Helmet>
              <title>
                {this.props.sport !== 'football' ? this.props.sport.toString().toUpperCase() : 'Soccer'} Streams - {this.props.sport !== 'football' ? `The highest quality website for ${this.props.sport.toString().toUpperCase()} live stream online` : 'The Home of Qualities'}
              </title>
              <meta name="description" content={`StreamsGate - Streams links for all ${this.props.sport !== 'football' ? this.props.sport.toString() : 'soccer'} streams matches. Watch the latest games for free!`} />
            </Helmet>
          </div>
      <div className="container main-content">
      <div className="sidebar">
        <div className="sublink">
          <a className="lnk" href="https://streamer.soccerstreams-100.tv">
            Submit Link
          </a>
        </div>
        <ul className="social-links">
          <li>
            <a className="social-tooltip customize-unpreviewable" title="Soccer Streams"
               href="https://new.soccerstreams-100.tv/">
              <img alt="Soccer Streams" src="https://a.espncdn.com/combiner/i?img=%2Fredesign%2Fassets%2Fimg%2Ficons%2FESPN%2Dicon%2Dsoccer.png&w=80&h=80&scale=crop&cquality=40&location=origin" className="lazyloaded"/>
            </a>
          </li>
          <li>
            <a className="social-tooltip customize-unpreviewable" title="NBA Streams"
               href="https://live1.nbastreams100.com/">
              <img
                  alt="NBA Streams"
                  src="https://a1.espncdn.com/combiner/i?img=%2Fredesign%2Fassets%2Fimg%2Ficons%2FESPN%2Dicon%2Dbasketball.png&w=80&h=80&scale=crop&cquality=40&location=origin"
                  className="lazyloaded"
              />
            </a>
          </li>
          <li>
            <a
                rel="noopener noreferrer"
                className="social-tooltip customize-unpreviewable"
                title="NFL Streams"
                href="https://nflstreams-100.tv/"
               
            >
              <img
                  alt="NFL Streams"
                  src="https://a1.espncdn.com/combiner/i?img=%2Fredesign%2Fassets%2Fimg%2Ficons%2FESPN%2Dicon%2Dfootball.png&w=80&h=80&scale=crop&cquality=40&location=origin"
                  className="lazyloaded imageLoaded"
              />
            </a>
          </li>
          <li>
            <a
                rel="noopener noreferrer"
                className="social-tooltip customize-unpreviewable"
                title="MLB Streams"
                href="https://new.mlbstreams100.com/"
               
            >
              <img
                  alt="MLB Streams"
                  src="https://a1.espncdn.com/combiner/i?img=%2Fredesign%2Fassets%2Fimg%2Ficons%2FESPN%2Dicon%2Dbaseball.png&w=80&h=80&scale=crop&cquality=40&location=origin"
                  className="lazyloaded imageLoaded"
              />
            </a>
          </li>
          <li>
            <a
                rel="noopener noreferrer"
                className="social-tooltip customize-unpreviewable"
                title="NHL Streams"
                href="https://new.nhlstreams100.com"
               
            >
              <img
                  alt="NHL Streams"
                  src="https://a1.espncdn.com/combiner/i?img=%2Fredesign%2Fassets%2Fimg%2Ficons%2FESPN%2Dicon%2Dhockey.png&w=80&h=80&scale=crop&cquality=40&location=origin"
                  className="lazyloaded imageLoaded"
              />
            </a>
          </li>
          <li>
            <a
                rel="noopener noreferrer"
                className="social-tooltip customize-unpreviewable"
                title="MMA Streams"
                href="https://live1.mmastreams100.com/"
               
            >
              <img
                  alt="MMA Streams"
                  src="https://a1.espncdn.com/combiner/i?img=%2Fredesign%2Fassets%2Fimg%2Ficons%2FESPN%2Dicon%2Dmma.png&w=80&h=80&scale=crop&cquality=40&location=origin"
                  className="lazyloaded imageLoaded"
              />
            </a>
          </li>
          <li>
            <a
                rel="noopener noreferrer"
                className="social-tooltip customize-unpreviewable"
                title="Boxing Streams"
                href="https://live1.boxingstreams100.com/"
               
            >
              <img
                  alt="Boxing Streams"
                  src="https://a1.espncdn.com/combiner/i?img=%2Fredesign%2Fassets%2Fimg%2Ficons%2FESPN%2Dicon%2Dboxing.png&w=80&h=80&scale=crop&cquality=40&location=origin"
                  className="lazyloaded imageLoaded"
              />
            </a>
          </li>
          <li>
            <a
                rel="noopener noreferrer"
                className="social-tooltip customize-unpreviewable"
                title="F1 Streams"
                href="https://main.f1streams100.com/"
               
            >
              <img
                  alt="F1 Streams"
                  src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/leagues/500/f1.png"
                  className="lazyloaded imageLoaded"
              />
            </a>
          </li>
          <li>
            <a
                rel="noopener noreferrer"
                className="social-tooltip customize-unpreviewable"
                title="MotoGP Streams"
                href="https://main.motogpstreams100.com/"
               
            >
              <img
                  alt="MotoGP Streams"
                  src="https://a.espncdn.com/combiner/i?img=/redesign/assets/img/icons/ESPN-icon-nascar.png&h=80&w=80&scale=crop&cquality=40"
                  className="lazyloaded imageLoaded"
              />
            </a>
          </li>
        </ul>
      </div>
        { this.state.loaded === true ?
          (this.state.data.length > 0 ? (this.props.sport === 'boxing' ? [{lgn:'boxing'}] : this.props.sport === 'motogp' ? [{lgn:'motogp'}] : this.state.data).map(l => ((this.props.sport === 'boxing' ||this.props.sport === 'motogp')  ? this.state.data : l.gs).map(item =>
                  !Boolean(item.cm) && !Boolean(item.s?.tp?.cm) && (<GameItem key={item.id} game={item} league={l.lgn} sport={this.props.sport ? this.props.sport : 'football'}/>))) :
              (<div className="missing-live-videos center-text"><span className="empty-event-label">There are no scheduled matches.</span></div>)) :
            (<div className="loading center-text"><span className="empty-event-label"> Loading... </span></div>)
        }
        {this.state.loaded === true && this.state.seoData.content && this.state.seoData.content.rendered.length > 0 && (
            <div className="seo"><div className="seo-body" dangerouslySetInnerHTML={{__html: this.state.seoData.content.rendered}}/></div>)
      }
      </div></>
    );
  }
}
export default Sport;
