import axios from 'axios';

const url = '/api/sport/';
const tops = ["367","364","359","382","360","363","86","83","1068","111","103","104","110","114","132","124","160","176"]

class WebService {
    static getFixture(league) {
      return new Promise(async(resolve, reject) => {
          try {
              const res = await axios.get(url+league);
              const data = res.data;
              if(league==='football'){
                  const gs = data.map(er => er.gs).flat().filter(gs => tops.includes(gs.c[0].i) || tops.includes(gs.c[1].i) ).filter(gs => !gs.s.tp.cm)
                  if(gs.length > 0){
                      data.unshift({
                          d: "20220213",
                          gs,
                          lgn: "Top Games"
                      })
                  }
              }
              resolve(
                  data
              );
          } catch (err) {
              reject(err);
          }
      });
    }
    /*static getSeo(league) {
      let uid = leagues.find(x=>{return x.name === league}).uid
      return new Promise(async(resolve, reject) => {
          try {
              const res = await axios.get('https://api.soccerstreams-100.com/wp-json/wp/v2/pages/'+uid);
              const data = res.data;
              resolve(
                  data
              );
          } catch (err) {
              reject(err);
          }
      });
    }*/
}

export default  WebService;
