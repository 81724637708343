import React, { Component } from 'react';
import WebService from "../WebService";

class Page extends Component {
  state = {
    seoData:[],
    loaded: false,
    error: ''
  }
  async componentDidMount() {
    try {
      this.setState({
        loaded: true,
        seoData: await WebService.getSeo(this.props.page ? this.props.page : 'null')
      });
    } catch (err) {
      this.setState({error:err.message,loaded: true});
    }
	}
  render(){
    return(
      <div className="container main-content">
        { this.state.loaded === true ?
          (this.state.seoData.content && this.state.seoData.content.rendered.length > 0 ?
            (<div className="seo">
            <h3>{this.props.page.split('-').join(' ')}</h3>
              <div className="seo-body" dangerouslySetInnerHTML={{__html: this.state.seoData.content.rendered}}/>
            </div>) : (
          <div className="missing-live-videos center-text">
            <span className="empty-event-label">Error 404 Not Found.</span>
          </div>
        )) : (<div className="loading center-text">
          <span className="empty-event-label">Loading...</span>
        </div>)
      }
      </div>
    );
  }
}
export default Page;
