import React from 'react';
import './App.css';
import { BrowserRouter as Router,Route,Switch} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Sport from './components/Sport';
import Page from './components/Page';

function App() {
  return (
      <Router>
        <Header/>
        <Switch>
          <Route exact path={`/`} component={() => <Sport sport={'football'} />} />
          <Route exact path={`/nba`} component={() => <Sport sport={'nba'} />} />
          <Route exact path={`/nfl`} component={() => <Sport sport={'nfl'} />} />
          <Route exact path={`/mlb`} component={() => <Sport sport={'mlb'} />} />
          <Route exact path={`/nhl`} component={() => <Sport sport={'nhl'} />} />
          <Route exact path={`/mma`} component={() => <Sport sport={'mma'} />} />
          <Route exact path={`/boxing`} component={() => <Sport sport={'boxing'} />} />
          <Route exact path={`/formula-one`} component={() => <Sport sport={'f1'} />} />
          <Route exact path={`/moto-gp`} component={() => <Sport sport={'motogp'} />} />
          <Route exact path={`/terms-and-conditions`} component={() => <Page page={'terms-and-conditions'} />} />
          <Route exact path={`/privacy-policy`} component={() => <Page page={'privacy-policy'} />} />
          <Route exact path={`/contact-us`} component={() => <Page page={'contact-us'} />} />
          <Route component={Page} />
          </Switch>
        <Footer/>
      </Router>
  );
}

export default App;
